import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import 'friendly-challenge/widget';
import 'bootstrap';

import './bookmark';

import './offcanvas';
import './smoothScroll';
import './modal';
import './seminar-filter';

import './tooltips';
import './calendar';
import './print';

// import './aos';

// import './scrollspy';

import './watchForHover';

import './browserSync';
