$(document).ready(function($) {

	let bookmarkIdsStore = localStorage.getItem('bookmarkIds');
	let bookmarkIds = [];
	if(bookmarkIdsStore) {
		bookmarkIds = JSON.parse(bookmarkIdsStore);
	}

	let bookmarkViewTrigger = $('#bookmark-view-trigger');
	let bookmarkTriggers = $('.module-columns__seminar__bookmark');
	let bookmarkViewTriggerCounter = $('#bookmark-view-trigger-counter');
	let bookSeminarButtons = $('.btn-book-seminar');
	let bookmarkViewPosts = $('#bookmark-view-posts');
	let bookmarkViewLoadingSpinner = $('#bookmark-view-loading-spinner');
	let bookmarkViewBookAllButton = $('#bookmark-view-book-all-button');

	// activate hearts
	$(bookmarkTriggers).each(function () {
		let postId = $(this).attr('data-post-id');
		if(postId && bookmarkIds.includes(postId)) {
			$(this).addClass('active');
		}
	});

	setViewTriggerCounter(bookmarkIds.length);

	$(bookmarkTriggers).click(toggleBookmark);
	$(bookSeminarButtons).click(goToBookSeminar);
	$(bookmarkViewBookAllButton).click(goToBookAllSeminars);

	function toggleBookmark() {

		let postId = $(this).attr('data-post-id');

		if(postId) {
			if(bookmarkIds.includes(postId)) {
				// remove bookmark
				const index = bookmarkIds.indexOf(postId);
				if (index > -1) {
					bookmarkIds.splice(index, 1);
					$(this).removeClass('active');
					setViewTriggerCounter(bookmarkIds.length);
				}
			} else {
				// add bookmark
				bookmarkIds.push(postId);
				$(this).addClass('active');
				setViewTriggerCounter(bookmarkIds.length);
			}
			localStorage.setItem('bookmarkIds', JSON.stringify(bookmarkIds));
		}

	}

	function setViewTriggerCounter(counter) {

		if(counter > 0) {
			$(bookmarkViewTrigger).addClass('active');
		} else {
			$(bookmarkViewTrigger).removeClass('active');
		}

		$(bookmarkViewTriggerCounter).empty();
		$(bookmarkViewTriggerCounter).text(counter + ' Seminar' + (counter === 1 ? '' : 'e'));
	}

	function goToBookSeminar() {
		let seminarId = $(this).attr('data-seminarid');
		let href = $(this).attr('data-href');
		if(seminarId && href) {
			localStorage.setItem('seminarToBook', seminarId);
			window.location.href = href;
		}
	}

	function goToBookAllSeminars() {
		let href = $(this).attr('data-href');
		if(href) {
			let seminarIds = [];
			$('#bookmark-view .module-seminar__seminar-id').each(function() {
				let seminarId = $(this).attr('data-seminarid');
				if (seminarId) {
					seminarIds.push(seminarId);
				}
			});

			localStorage.setItem('seminarsToBook', JSON.stringify(seminarIds));
			window.location.href = href;
		}
	}

	function removeBookmarkFromView() {
		let postId = $(this).attr('data-post-id');

		if(postId) {
			if(bookmarkIds.includes(postId)) {
				// remove bookmark
				const index = bookmarkIds.indexOf(postId);
				if (index > -1) {
					bookmarkIds.splice(index, 1);

					$(bookmarkTriggers).each(function () {
						let innerPostId = $(this).attr('data-post-id');
						if(innerPostId === postId) {
							$(this).removeClass('active');
						}
					});

					setViewTriggerCounter(bookmarkIds.length);
				}
				localStorage.setItem('bookmarkIds', JSON.stringify(bookmarkIds));

				if(bookmarkIds.length < 2) {
					$(bookmarkViewBookAllButton).addClass('inactive');
				}

				if(bookmarkIds.length > 0) {
					// Remove from Bookmark view
					let item = $(this).closest('.module-wrapper');
					if(item) {
						$(item).remove();
					}
				} else {
					// eslint-disable-next-line no-undef
					closeBookmarkViewExternal();
				}

			}
		}

	}

	// eslint-disable-next-line no-unused-vars
	window.loadBookmarks = function() {

		$(bookmarkViewPosts).empty();

		$.ajax({
			type: 'POST',
			url: ajaxurl, // eslint-disable-line
			dataType: 'html', // add data type
			data: {
				action : 'get_bookmark_posts',
				bookmarkIds: bookmarkIds
			},
			success: function(response) {
				$('#bookmark-view-posts').html(response);
				$(bookmarkViewLoadingSpinner).addClass('inactive');

				if(bookmarkIds.length > 1) {
					$(bookmarkViewBookAllButton).removeClass('inactive');
				}

				let triggers = $('#bookmark-view .module-columns__seminar__bookmark');
				$(triggers).each(function () {
					$(this).addClass('active');
				});
				$(triggers).click(removeBookmarkFromView);
				$('#bookmark-view .btn-book-seminar').click(goToBookSeminar);
				$('#bookmark-view [data-toggle="tooltip"]').tooltip();
				// eslint-disable-next-line no-undef
				initBookmarkCalendarDownload();
				$('#bookmark-view .btn-print').css('display', 'none');
			}
		});
	};

});
