$(document).ready(function($) {

	let btnPrint = $('.btn-print');

	$(btnPrint).click(print);

	function print() {
		window.print();
	}

});
