import { Component, Property } from 'immutable-ics';
import moment from 'moment';
import { saveAs } from 'file-saver';

$(document).ready(function($) {

	let btnCalendar = $('.btn-calendar');

	$(btnCalendar).click(downloadCalendar);

	window.initBookmarkCalendarDownload = function() {
		let btnCalendarView = $('#bookmark-view .btn-calendar');
		$(btnCalendarView).click(downloadCalendar);
	};

	function downloadCalendar() {

		const properties = [];

		let title = $(this).attr('data-title');
		if(title) {
			properties.push(new Property({ name: 'SUMMARY', value: 'Seminar: ' + title }));
		}

		let address = $(this).attr('data-address');
		if(address) {
			properties.push(new Property({ name: 'LOCATION', value: address }));
		}


		let startDateString = $(this).attr('data-startdate');
		let startDate;
		if(startDateString) {
			startDate = moment.utc(startDateString, 'DD.MM.YYYY').toDate();
			properties.push(new Property({
				name: 'DTSTART',
				parameters: { VALUE: 'DATE' },
				value: startDate
			}));
		}

		let endDateString = $(this).attr('data-enddate');
		let endDate;
		if(endDateString && startDateString !== endDateString) {
			endDate = moment.utc(endDateString, 'DD.MM.YYYY').toDate();
			endDate.setDate(endDate.getDate() + 1);
			properties.push(new Property({
				name: 'DTEND',
				parameters: { VALUE: 'DATE' },
				value: endDate
			}));
		}

		properties.push(new Property({ name: 'TZID', value: 'Europe/Berlin' }));
		properties.push(new Property({ name: 'URL', value: 'https://www.bildungswerk-stenden.de' }));

		const calendar = new Component({
			name: 'VCALENDAR',
			properties: [
				new Property({ name: 'VERSION', value: 2 }),
				new Property({ name: 'X-WR-CALNAME', value: title }),
			],
			components: [
				new Component({
					name: 'VEVENT',
					properties: properties
				})
			]
		});

		const calendarString = calendar.toString();
		let blob = new Blob([calendarString], {type: 'text/plain;charset=utf-8'});
		saveAs(blob, 'seminar.ics');

	}

});
