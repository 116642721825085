import Litepicker from 'litepicker';
$(document).ready(function($) {

	let categoryDropdown = $('#seminar-category-dropdown');
	let educationalLeaveCheckbox = $('#seminar-filter-educational-leave');
	let tourOperatorCheckbox = $('#seminar-filter-tour-operator');
	let seminarForWomenCheckbox = $('#seminar-filter-seminar-for-women');
	let loadingScreen = $('#loading-screen');

	let picker;
	let pickerItem = document.getElementById('litepicker');

	$(categoryDropdown).change(changeCategoryDropdown);
	$(educationalLeaveCheckbox).click(clickEducationalLeave);
	$(tourOperatorCheckbox).click(clickTourOperator);
	$(seminarForWomenCheckbox).click(clickSeminarForWomen);

	function changeCategoryDropdown () {
		let optionSelected = $('option:selected', this); // eslint-disable-line
		let startDate = picker.getStartDate();
		let endDate = picker.getEndDate();
		addParamsAndReload (this.value, startDate, endDate);
	}

	if(pickerItem) {
		// eslint-disable-next-line no-unused-vars
		picker = new Litepicker({
			element: pickerItem,
			format: 'DD.MM.YYYY',
			lang: 'de-DE',
			minDate: new Date(),
			singleMode: false,
			resetButton: true,
			numberOfColumns: 2,
			numberOfMonths: 2,
			// eslint-disable-next-line no-unused-vars
			setup: (picker) => {
				picker.on('selected', (startDate, endDate) => {
					let hasEducationalLeave = document.getElementById('seminar-filter-educational-leave').checked;
					addParamsAndReload (null, startDate, endDate, hasEducationalLeave);
				});
				picker.on('clear:selection', () => {
					let hasEducationalLeave = document.getElementById('seminar-filter-educational-leave').checked;
					addParamsAndReload (null, null, null, hasEducationalLeave);
				});
			},
		});
	}

	function clickEducationalLeave () {
		let startDate = picker.getStartDate();
		let endDate = picker.getEndDate();
		addParamsAndReload (null, startDate, endDate);
	}

	function clickTourOperator () {
		let startDate = picker.getStartDate();
		let endDate = picker.getEndDate();
		addParamsAndReload (null, startDate, endDate);
	}

	function clickSeminarForWomen () {
		let startDate = picker.getStartDate();
		let endDate = picker.getEndDate();
		addParamsAndReload (null, startDate, endDate);
	}

	function addParamsAndReload (baseUrl, startDate, endDate) {
		let educationalLeave = document.getElementById('seminar-filter-educational-leave').checked;
		let tourOperator = document.getElementById('seminar-filter-tour-operator').checked;
		let seminarForWomen = document.getElementById('seminar-filter-seminar-for-women').checked;

		$(loadingScreen).addClass('active');
		let isFirst = true;
		let path = baseUrl ? baseUrl : window.location.origin + window.location.pathname;

		if(startDate) {
			path += (isFirst ? '?' : '&');
			path += 'start-date=' + startDate.format('YYYY-MM-DD');
			isFirst = false;
		}

		if(endDate) {
			path += (isFirst ? '?' : '&');
			path += 'end-date=' + endDate.format('YYYY-MM-DD');
			isFirst = false;
		}

		if(educationalLeave) {
			path += (isFirst ? '?' : '&');
			path += 'educational-leave=true';
			isFirst = false; // eslint-disable-line
		}

		if(tourOperator) {
			path += (isFirst ? '?' : '&');
			path += 'tour-operator=true';
			isFirst = false; // eslint-disable-line
		}

		if(seminarForWomen) {
			path += (isFirst ? '?' : '&');
			path += 'seminar-for-women=true';
			isFirst = false; // eslint-disable-line
		}

		window.location.href = path + '#filter';
	}
});
